import classNames from "classnames";
import Button from "../../../ui/Button/Button";
import Label from "../../../ui/Label/Label";
import { useMemo } from "react";
import { formatId, numWord } from "../../../../utils";
import { RepaymentMethods } from "../../../../constants";
import "moment/locale/ru";
import PoolLabel from "../../../ui/PoolLabel/PoolLabel";
import StatusLabel from "../../../ui/Card/StatusLabel";
import ProgressLabel from "../../../ui/Label/ProgressLabel";
import { orderStatusLabelMap } from "../../../../admin/components/AdminTable/AdminTableFormaters";
import ReleaseLink from "../../../ui/ReleaseLink/ReleaseLink";
import RejectOrderInfo from "./RejectOrderInfo";
import useMobile from "../../../../hooks/useMobile";
import { CopyOrderIdButton } from "./CopyOrderIdButton";

const CardHorizontalOrder = ({ tokenData, isArchive = false }) => {
  const status = useMemo(() => {
    if (tokenData.orders && tokenData.orders.length > 0) {
      return tokenData.orders[0]?.status;
    }
  }, [tokenData]);

  const orderType = useMemo(() => {
    if (tokenData.orders && tokenData.orders.length > 0) {
      return tokenData.orders[0]?.orderType;
    }
  }, [tokenData]);

  const isMobile = useMobile();

  const cardClass = classNames({
    card: true,
    "card_h card_token": !isMobile,
  });

  if (!tokenData) return null;

  const { orders, pool, building, developer, emitter } = tokenData;
  // const availableTokens = formatTokensForDisplay(tokenData.amount);
  return (
    <article className={cardClass}>
      <div className="card__wrapper">
        <div className="card__labels-list">
          <StatusLabel data={tokenData} />
          <Label labelType="secondary">100 ЦФА = стоимости 1 м²</Label>
          {pool?.repaymentMethod && (
            <Label labelType="secondary">
              {RepaymentMethods[pool.repaymentMethod]}
            </Label>
          )}
        </div>

        <PoolLabel addClass="card__pool-label">
          {pool?.name || formatId(pool?.id)}
        </PoolLabel>

        <img className="card__img" src={building?.imagesLinks[0]} alt="" />
      </div>

      <div className="card__body">
        <div className="card__body-container">
          {status && (
            <div>
              <ProgressLabel status={status}>
                {
                  orderStatusLabelMap[
                    orderType === "BURNING" ? "CLOSED_BURNING" : status
                  ]
                }
              </ProgressLabel>
            </div>
          )}
          <RejectOrderInfo orders={orders} />
          {orders[0] && <CopyOrderIdButton value={orders[0].id} />}
        </div>

        <div className="sell-info">
          <div className="sell-info__col">
            <span className="sell-info__title">О жилом комплексе</span>
            <span className="sell-info__key">{building?.name}</span>
            <p className="sell-info__value">{developer?.name}</p>

            <span className="sell-info__key">{building?.city}</span>
            <p className="sell-info__value">{building?.district}</p>

            {emitter && (
              <>
                <span className="sell-info__key">{emitter?.name}</span>
                <p className="sell-info__value">Эмитент</p>
              </>
            )}

            <span className="sell-info__key">
              100 ЦФА = {orders[0]?.release?.price} ₽
            </span>

            <p className="sell-info__value">Цена 100 ЦФА в выпуске до ввода в эксплуатацию</p>
          </div>

          <div className="sell-info__col">
            {orderType !== "BURNING" && status !== "CLOSED" && (
              <>
                <span className="sell-info__title">Количество</span>
                <span className="sell-info__key">{tokenData.amount} ЦФА</span>

                <p className="sell-info__value">Приобретаемые ЦФА</p>

                <span className="sell-info__key">
                  {tokenData.amount / 100} м²
                </span>
                <p className="sell-info__value">
                  {numWord(tokenData.amount / 100, [
                    "токенизированный",
                    "токенизированных",
                    "токенизированных",
                  ])}{" "}
                  м²
                </p>
              </>
            )}

            <span className={`sell-info__key`}>
              {<ReleaseLink ellipsis release={orders[0].release} />}
            </span>
          </div>

          {pool?.repaymentMethod === "GUARANTEED_INCOME" && (
            <div className="sell-info__col">
              <span className="sell-info__title">Фиксированный доход</span>
              <span className="sell-info__key">{pool?.exitPrice} ₽</span>
              <p className="sell-info__value">Со 100 ЦФА после ввода в эксплуатацию</p>
            </div>
          )}

          {pool?.repaymentMethod === "SALES_INCOME" && (
            <div className="sell-info__col">
              <span className="sell-info__title">Доход от продаж</span>
              <span className="sell-info__key">
                {pool?.exitPrice} ₽ + % от продажи
              </span>
              <p className="sell-info__value">Фиксированный доход</p>
            </div>
          )}
        </div>

        {status && !isArchive && (
          <div className="card__btns">
            <Button
              size={"xs"}
              addClass="card__buy-btn"
              target={"_blank"}
              isLink={`/${tokenData.pool.id}`}
            >
              {[
                "WAITING_FOR_CONFIRMATION",
                "PAYMENT_CONFIRMATION_SENT",
                "PAYMENT_CONFIRMED",
                "CANCELED_BY_OIS",
                "CANCELED_BY_INVESTOR",
                "CANCEL_CONFIRMED_BY_OIS",
                "CANCELED",
                "RELEASE_CANCELED",
                "EXPIRED",
              ].includes(status)
                ? "К странице пула"
                : status === "WAITING_ISSUE"
                  ? "Купить еще"
                  : "Продолжить покупку"}
            </Button>
          </div>
        )}
      </div>
    </article>
  );
};

export default CardHorizontalOrder;
